module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Present Helper","short_name":"PH","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/present-helper-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"07e340e5c40c799da7770cdda94f26cf"},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en","de"],"defaultLanguage":"en","siteUrl":"https://present-helper.com","i18nextOptions":{"defaultNS":"common","debug":false,"lowerCaseLng":true,"saveMissing":false,"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/privacyPolicy.en","languages":["en"]},{"matchPath":"/privacyPolicy.de","languages":["de"]},{"matchPath":"/:lang?/geschenkideen/:sd/:kd","getLanguageFromPath":true,"excludeLanguages":["en","de"]},{"matchPath":"/:lang?/geschenkideen/hobbys/:sd/:kd","getLanguageFromPath":true,"excludeLanguages":["en","de"]},{"matchPath":"/:lang?/geschenkideen/persoenlich/:sd/:kd","getLanguageFromPath":true,"excludeLanguages":["en","de"]},{"matchPath":"/:lang?/geschenkideen/zeit-schenken/:sd/:kd","getLanguageFromPath":true,"excludeLanguages":["en","de"]},{"matchPath":"/:lang?/gift-ideas/:sd/:kd","getLanguageFromPath":true,"excludeLanguages":["en","de"]},{"matchPath":"/:lang?/gift-ideas/hobbies/:sd/:kd","getLanguageFromPath":true,"excludeLanguages":["en","de"]},{"matchPath":"/:lang?/gift-ideas/personal/:sd/:kd","getLanguageFromPath":true,"excludeLanguages":["en","de"]},{"matchPath":"/:lang?/gift-ideas/time-together/:sd/:kd","getLanguageFromPath":true,"excludeLanguages":["en","de"]}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
