exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-imprint-js": () => import("./../../../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-de-js": () => import("./../../../src/pages/privacyPolicy.de.js" /* webpackChunkName: "component---src-pages-privacy-policy-de-js" */),
  "component---src-pages-privacy-policy-en-js": () => import("./../../../src/pages/privacyPolicy.en.js" /* webpackChunkName: "component---src-pages-privacy-policy-en-js" */),
  "component---src-templates-gift-ideas-template-js": () => import("./../../../src/templates/giftIdeasTemplate.js" /* webpackChunkName: "component---src-templates-gift-ideas-template-js" */)
}

